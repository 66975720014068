//
// user.js
// Use this to write your custom JS
//

const submitMail = document.querySelectorAll('.sendMsg');

function saToast(message, icon, position) {
	if(!position)
		position = 'top-end';
	
	const Toast = Swal.mixin({
		toast: true,
		position: position,
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
	});

	if(!icon)
		icon = 'success';
	
	Toast.fire({
	  icon: icon,
	  title: message
	})
}

function saMessage(message, icon, callback){
	Swal.fire(
   {
      icon: icon,
      title: message,
   }).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (callback) {
			callback();
		} 
	});
}

submitMail.forEach((submitF) => {
  submitF.addEventListener('click', function (e) {
    e.preventDefault();

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
    	    var response = JSON.parse(this.responseText);
            if(response) {
	        if(response.error == false)
	            saToast("message sent","info");
                else
	            saToast(response.msg, "error")
            }

        }
    };
    var token = "2FAA9B75-D13B-4EB0-8800-82BB5858BB82";
    var contactName = document.getElementById("contactName").value;
    var contactSpace = document.getElementById("contactSpace").value;
    var contactMessage = document.getElementById("contactMessage").value;

    xhttp.open("POST", "https://rentalpoint3.com/smtp", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.send(encodeURI("token="+token+"&contactName="+contactName+"&contactSpace="+contactSpace+"&contactMessage="+contactMessage));

    saMessage('Sending message ...','info');

  });
});



